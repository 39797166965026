<template>
    <el-row :gutter="160">
        <el-col :span="24" style="text-align: left;">
            <el-select v-model="curGroup" value-key="id" style="width:180px;" @change="onSelectGroup" placeholder="选择区服列表">
                <el-option v-for="item in serverGroups" :key="item.id" :label="item.name" :value="item">
                </el-option>
            </el-select>                
            <el-select v-model="curServerid" value-key="id" style="width:180px; margin-left: 8px;" @change="onSelectServer" placeholder="区服">
                <el-option v-for="item in serverItems" :key="item.id" :label="item.title" :value="item.id">
                </el-option>
            </el-select>
            <el-input v-model="unionname" placeholder="搜索公会名" style="margin-left: 8px;width: 120px;"></el-input>
        </el-col>
        <el-col :span="24">
            <el-table :data="filterItems" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:8+'px'}" class="serverlist">
                <el-table-column prop="union_id"   label="公会id"   show-overflow-tooltip sortable></el-table-column>
                <el-table-column prop="union_name" label="名称"     show-overflow-tooltip></el-table-column>
                <el-table-column prop="union_lv"   label="等级"     show-overflow-tooltip sortable></el-table-column>
                <el-table-column prop="union_icon" label="图标"     show-overflow-tooltip></el-table-column>
                <el-table-column prop="union_num"  label="成员数量" show-overflow-tooltip sortable></el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button @click.native.prevent="onDetailItem(scope.row)" type="success" icon="el-icon-more"
                            size="small">详情</el-button>
                        <el-button @click.native.prevent="onListMembers(scope.row)" type="primary" icon="el-icon-document"
                            size="small">成员列表</el-button>                            
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
              @current-change="handleCurrentChange"
              layout="total, prev, pager, next"
              background
              :page-size="20000"
              :total="total">
            </el-pagination>
        </el-col>
		<!-- ========弹框BEGIN====== -->
		<el-dialog :title="formTitle" :visible.sync="dialogDetail" width="800px" :close-on-click-modal="false" center class="formparams">
			<el-form :model="curRow">         
                <el-form-item label="公会id" :label-width="formLabelWidth">
                    <el-input v-model="curRow.union_id"></el-input>
                </el-form-item> 
                <el-form-item label="公会名称" :label-width="formLabelWidth">
                    <el-input v-model="curRow.union_name"></el-input>
                </el-form-item> 
                <el-form-item label="公会会长" :label-width="formLabelWidth">
                    <el-input v-model="curRow.president"></el-input>
                </el-form-item>                 
                <el-form-item label="公会宣言" :label-width="formLabelWidth">
                    <el-input v-model="curRow.notice"></el-input>
                </el-form-item> 
                <el-form-item label="公会等级" :label-width="formLabelWidth">
                    <el-input v-model="curRow.union_lv"></el-input>
                </el-form-item> 
                <el-form-item label="成员数量" :label-width="formLabelWidth">
                    <el-input v-model="curRow.union_num"></el-input>
                </el-form-item> 
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button type="primary" icon="el-icon-edit-outline" @click="onModifyName">修改名称</el-button>
                <el-button type="primary" icon="el-icon-s-flag" @click="onModifyNotice">修改宣言</el-button>
                <el-button type="primary" icon="el-icon-s-custom" @click="onModifyPresident">修改会长</el-button>
                <el-button type="danger" icon="el-icon-delete-solid" @click="onDissolveUnion">解散公会</el-button>                
			</div>
		</el-dialog>
		<!-- ========弹框END====== -->
        <el-dialog title="提示" :visible.sync="dialogLoading" width="24%" :close-on-click-modal="false" :show-close="false">
          <span>查询中...</span>
        </el-dialog>
        <!-- ========弹框BEGIN====== -->
        <el-dialog :title="formOp.title" :visible.sync="dialogFormOp" width="30%" center class="formparams" :close-on-click-modal="false">
            <el-form :model="formOp">
                <el-form-item label="修改为:">
                    <el-input v-model="formOp.value" placeholder="请输入修改"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" icon="el-icon-check" @click="onCommitFormOp">确 定</el-button>
            </div>
        </el-dialog>
        <!-- ========弹框END====== -->
        <!-- ========成员列表弹框BEGIN====== -->
        <el-dialog title="公会成员列表" :visible.sync="dialogListMember" width="900px" center class="formparams"
            :close-on-click-modal="false">
            <el-table ref="multipleTable" :data="unionmembers" :height="tableHeight" border style="width: 100%" :row-class-name="tableRowClassName"
                :cell-style="{padding:4+'px'}" class="serverlist">
                <el-table-column v-for="item in memberLabelArr" :key="item.key" :prop="item.prop" :label="item.label" show-overflow-tooltip></el-table-column>
            </el-table>
        </el-dialog>
    </el-row>
</template>

<script>
    import {
        mapGetters
    } from 'vuex'

    export default {
        data() {
            return {
                dialogLoading: false,
                unionList: [],
                curPage: 1,
                total: 0,
                curGroup: '',
                curServerid: '',
                serverGroups: [],
                serverItems: [],
                unionname: '',
                curRow: {},
                dialogDetail:false,
                formTitle: '',
                dialogFormOp:false,
                formOp : {
                    op:'',
                    title:'',
                    value:'',
                },
                dialogListMember:false,
                unionmembers:[],
                memberLabelArr : [],
                tableHeight: document.documentElement.clientHeight-136-32, 
                formLabelWidth: '100px',
            }
        },
        created: function() {
            this.refreshGroupList();
            this.memberLabelArr = [{prop:'member_id',label:'角色id'}, {prop:'member_name',label:'昵称'}, {prop:'member_mainlevel', label:'等级'}, {prop:'member_head',label:'头像'}, {prop:'member_VIP',label:'VIP'},{prop:'member_post',label:'职位'},{prop:'member_lasttime',label:'最后登录'}];
        },
        computed: {
            ...mapGetters(
                ['gameHttp']
            ),
            // 公会名过滤显示
            filterItems() {
                return this.unionList.filter((data) => {
                    // 没有搜索条件 或 包含搜索字则显示
                    return !this.unionname || (data.union_name.includes(this.unionname));
                })
            },
        },
        methods: {    
            // 刷新分组列表
            refreshGroupList() {
                this.gameHttp(2006, {
                    plain: 1
                }).then((response) => {
                    this.serverGroups = response.data.list
                    this.serverGroups.splice(0, 0, {
                        id: -1,
                        name: "全部区服列表"
                    })
                })
            },
            // 选择分组
            onSelectGroup(item) {
                this.curServerid = "";
                this.gameHttp(2009, { // 拉取服务器列表
                    listid: item.id,
                }).then((response) => {
                    this.serverItems = response.data.list;
                });
            },
            // 选择区服
            onSelectServer() {
                this.dialogLoading = true;
                this.unionList = [];
                this.refreshunionlist();
            },
            // 查询列表
            refreshunionlist() {
                let rqs = {
                    server: this.curServerid,
                    page:this.curPage,
                };             
                this.gameHttp(3076, rqs).then((response) => {
                    this.dialogLoading = false;
                    if (0 == response.data.errcode) {
                        this.total = response.data.list.length;
                        this.unionList = response.data.list;
                    } else {
                        this.$message.error(response.data.errmsg);
                    }
                })
            },
            // 分页查看
            handleCurrentChange(page) {
                this.curPage = page;
                this.refreshunionlist();
            },
            // 公会详情
            onDetailItem(row) {
                console.log("onDetailItem", row);
                this.curRow = row;
                this.dialogDetail = true;
                this.formTitle = this.curRow.union_name + "->详情";
                this.formOp.value = "";
            },
            // 公会成员列表
            onListMembers(row) {
                console.log("onListMembers", row);
                this.curRow = row;
                this.dialogListMember = true;
                this.dialogLoading = true;
                let rqs = {
                    server: this.curServerid,
                    union_id: row.union_id,
                };
                this.gameHttp(3078, rqs).then((response) => {
                    this.dialogLoading = false;
                    if (0 == response.data.errcode) {
                        this.unionmembers = response.data.list;
                    } else {
                        this.$message.error(response.data.errmsg);
                    }
                });
                this.unionmembers = [];
            },            
            // 修改公会名
            onModifyName() {
                this.formOp.op = "modify_name";
                this.formOp.title = "修改公会名";
                this.dialogFormOp = true;
                this.formOp.value = "";
            },
            // 修改公会宣言
            onModifyNotice() {
                this.formOp.op = "modify_placard";
                this.formOp.title = "修改公会宣言";
                this.dialogFormOp = true;
                this.formOp.value = "";
            },
            // 修改公会会长
            onModifyPresident() {
                this.formOp.op = "modify_president";
                this.formOp.title = "修改公公会长";
                this.dialogFormOp = true;
                this.formOp.value = "";
            },
            // 解散公会
            onDissolveUnion() {
                this.$confirm('是否解散[ '+this.curRow.union_name+' ]公会?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.formOp.op = "dissolve_union";
                    this.onCommitFormOp();
                }).catch(() => {})
            },
            // 提交修改
            onCommitFormOp() {
                this.dialogFormOp = false;
                this.dialogDetail = false;
                let rqs = {
                    op : this.formOp.op,
                    union_id: this.curRow.union_id,
                    value: this.formOp.value,
                    serverid: this.curServerid,
                };
                this.gameHttp(3077, rqs).then((response) => {
                    this.dialogLoading = false;
                    if (0 == response.data.errcode) {
                        this.$message.success("提交成功!");
                        // 更新下单条数据
                        let op = response.data.op;
                        if ("modify_name" == op) {
                            this.curRow.union_name = response.data.value;
                        } else if ("modify_placard" == op) {
                            //this.curRow.union_name = response.data.value;
                            console.log("更新宣言");
                        } else if ("modify_president" == op) {
                            //this.curRow.union_name = response.data.value;
                            console.log("更新会长");
                        } else if ("dissolve_union" == op) {
                            for (let i=0; i<this.unionList.length; i++) {
                                if (this.curRow == this.unionList[i]) {
                                    this.unionList.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    } else {
                        this.$message.error(response.data.errmsg);
                    }
                })
            },
            // 数据表色彩间隔效果     
            tableRowClassName({
                rowIndex
            }) {
                if (0 == rowIndex % 2) {
                    return 'warning-row';
                } else {
                    return 'success-row';
                }
            },
        },
    }
</script>

<style>
    .serverlist.el-table .warning-row {
        background: #f5f7fa;
    }

    .serverlist.el-table .success-row {
        background: #ffffff;
    }

    .serverlist.el-table {
        margin-top: 16px;
    }

    .serverlist {
        font-size: 12px;
    }
    
    .demo-table-expand {
        font-size: 0;
    }
    .demo-table-expand label {
        width: 90px;
        color: #99a9bf;
    }
    .demo-table-expand .el-form-item {
        margin-right: 0;
        margin-bottom: 0;
        width: 50%;
    }
</style>
